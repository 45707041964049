#app
{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	main    { flex: 1 0 auto; }
	#footer { flex: 0 0 auto; }
}

iframe
{
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

table
{
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

img
{
	vertical-align: middle;
	-webkit-user-drag: none;
	user-drag: none;
	@include userSelect(none);
}

.center-block
{
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.lazy[src]
{
	opacity: 0;
	will-change: opacity;
	@include transition(opacity 200ms);

	&.loaded { opacity: 1; }
}

.section
{
	position: relative;
	padding-top: 70px;
	padding-bottom: 70px;
	z-index: 0;

	&--no-pt { padding-top: 0.05px !important; }
	&--no-pb { padding-bottom: 0.05px !important; }

	&--base-bg { background-color: $primary-color; }
	&--gray-bg { background-color: #f9f9f9; }
	&--dark-bg { background-color: #333; }

	@include media-breakpoint-up(sm)
	{
		
	}

	@include media-breakpoint-up(md)
	{
		padding-top: 100px;
		padding-bottom: 100px;

		&--gutter
		{
			margin-left: 20px;
			margin-right: 20px;
		}
	}

	@include media-breakpoint-up(lg)
	{
		padding-top: 140px;
		padding-bottom: 140px;
	}

	@include media-breakpoint-up(xl)
	{
		
	}
}

/* parallax */
.jarallax
{
	background: {
		position: 50% 50%;
		repeat: no-repeat;
		size: cover;
	}

	.desktop & { background-attachment: fixed; }
}

.v-align
{
	@extend %height-full;

	&:before
	{
		content: "";
		@extend %width-0;
		@extend %height-full;
		margin-left: -4.5px;
	}

	&:before,
	>*
	{
		@extend %display-inline-block;
		@extend %v-middle;
	}

	>* { @extend %width-full; }
}

/* circled element */

.circled { @include border-radius(50%); }