/* --------------------------------
   recipes
-------------------------------- */

$gutter: 30px;

.recipes
{
	margin-top: -55px;

	.__inner {  }

	.__item
	{
		position: relative;
		width: 100%;
		background-color: $white;

		&:hover
		{
			.__image
			{
				img { transform: scale(1.2) translateZ(0); }
			}
		}
	}

	.__image
	{
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;
		min-height: 210px;
		padding-top: percentage(235/290);
		margin: 0;

		img
		{
			@extend %block-absolute--full;
			transform: translateZ(0);
			@include object-fit(cover, 50% 50%);
			@include transition(
				transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
				opacity 200ms
			);
		}
	}

	.__content
	{
		position: relative;
		width: 100%;
		padding: 20px $gutter/2;
		font-size: rem-calc(14px);
		color: #666;
	}

	.__title
	{
		font-weight: 700;
		text-transform: none;
	}
}

@include media-breakpoint-up(sm)
{
	.recipes
	{
		.__content { padding: $gutter; }
	}
}

@include media-breakpoint-up(md)
{
	.recipes { margin-top: -110px; }
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}