/* --------------------------------
   product single
-------------------------------- */

.product-single
{
	.__product-img
	{
		position: relative;
		margin-bottom: 40px;
		border: 1px solid #e5e5e5;
		background-color: $white;
		overflow: hidden;

		@include media-breakpoint-up(lg)
		{
			margin-right: 20px;
			margin-bottom: 0;
		}

		@include media-breakpoint-up(xl)
		{
			margin-right: 40px;
		}

		&:before
		{
			content: "";
			display: block;
			padding-top: percentage(440/430);
		}

		img
		{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			max-width: 100%;
			max-height: 100%;
			margin: auto;
		}
	}

	.__name
	{
		margin-bottom: 5px;
		text-transform: none;

		&:first-child { margin-top: -0.25em; }
	}

	.__categories { line-height: 1.5; }

	.product-price
	{
		margin-top: 20px;
		margin-left: -10px;
		margin-bottom: 20px;

		&__item { margin-left: 10px; }
	}

	.rating { margin-top: 25px; }

	.__add-to-cart
	{
		button[type=submit]
		{
			margin-top: 20px;
			white-space: nowrap;

			i
			{
				display: inline-block;
				vertical-align: top;
				margin-top: -7px;
				margin-bottom: -6px;
				margin-left: -5px;
				margin-right: 10px;
				line-height: 1;
				font-size: 2.2rem;
			}
		}
	}

	.quantity-counter
	{
		
	}

	.tab-nav
	{
		margin-bottom: -1px;

		a
		{
			border-bottom: 4px solid transparent;
			line-height: 35px;
			font-weight: 700;
			color: #aaa;

			&.active
			{
				color: #333;
				border-color: $primary-color
			}
		}
	}

	.tab-content
	{
		border-top: 1px solid #e5e5e5;

		&__item { padding-top: 45px; }
	}

	.description-table
	{
		margin-top: 20px;
		margin-bottom: 20px;
		line-height: 1.4;
		color: #333;

		&:first-child { margin-top: 0; }
		&:last-child { margin-bottom: 0; }

		tr
		{
			&:nth-of-type(odd) { background-color: #f6f6f6; }
		}

		td
		{
			padding: 10px 15px;
			height: 40px;

			&:first-child { padding-left: 20px; }
			&:last-child { padding-right: 20px; }
		}
	}

	.comments-list
	{
		&:first-child,
		.comment:first-child
		{
			margin-top: 0;
		}

		.rating
		{
			margin-top: 0;
			margin-left: auto;
		}
	}

	.__add-review
	{
		margin-top: 60px;

		.rating
		{
			margin-top: 30px;
			margin-bottom: 30px;
			direction: rtl;
			text-align: left;

			.__note
			{
				float: left;
				margin-right: 10px;
				font-size: 1.4rem;
				line-height: 1;
				letter-spacing: 0;
				direction: ltr;
			}
		}

		button[type=submit] { margin-top: 0; }
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}