/* --------------------------------
   product-preview
-------------------------------- */

$gutter: 10px;

.product-preview
{
	width: 100%;

	.__inner { margin-bottom: -$gutter; }

	.row
	{
		margin-left: -$gutter/2;
		margin-right: -$gutter/2;
	}

	.col,
	[class*="col-"]
	{
		padding-left: $gutter/2;
		padding-right: $gutter/2;
	}

	.__item
	{
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		margin-bottom: $gutter;
		@include transition(
			padding 500ms
		);

		.__intro-text
		{
			margin-left: auto;
			width: 100%;
			max-width: 580px;
		}

		.__image
		{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			overflow: hidden;
			@include transition(
				top 500ms,
				right 500ms,
				bottom 500ms,
				left 500ms
			);

			+ .__content { background-color: rgba(#333, 0.2); }

			img
			{
				display: block;
				width: 100%;
				height: 100%;
				@include object-fit(cover, 50% 50%);
				@include transition(
					transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
					opacity 200ms
				);
			}
		}

		.__content
		{
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			padding: 15px;
			background-color: #333;
			color: $white-color;
			@include transition(background-color 0.3s ease-in-out);
		}

		.__title { color: inherit; }

		.__link
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&:hover
		{
			.__image + .__content { background-color: rgba(#4a6099, 0.9); }
		}
	}
}

.product-preview--style-1
{
	.__item
	{
		height: 325px;

		.__image
		{

		}
	}
}

.product-preview--style-2
{
	.__item
	{
		height: 450px;

		.__image
		{

		}

		&:hover
		{
			padding: 20px;

			.__image
			{
				top: 20px;
				right: 20px;
				bottom: 20px;
				left: 20px;
			}
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	.product-preview--style-2
	{
		.__item
		{
			height: 550px;
		}
	}
}

@include media-breakpoint-up(lg)
{
	.product-preview--style-2
	{
		.__item
		{
			height: 650px;
		}
	}
}

@include media-breakpoint-up(xl)
{
	
}