/* --------------------------------
   footer
-------------------------------- */

$gutter: 30px;

#footer
{
	position: relative;

	.footer__item
	{
		width: 100%;
		margin-bottom: $gutter + 10px;

		&__title
		{
			font-weight: 700;
		}
	}

	.site-logo {  }

	address
	{
		line-height: 1.6;
		font-style: normal;

		p
		{
			margin-top: 10px;
			margin-bottom: 10px;

			&:first-child { margin-top: 0 !important; }
			&:last-child  { margin-bottom: 0 !important; }
		}

		a
		{
			&[href*="mailto"]
			{
				text-decoration: underline;

				&:hover,
				&:focus
				{
					text-decoration: none;
				}
			}
		}
	}

	&__navigation
	{
		&:first-child { margin-top: -10px; }

		ul {  }

		li { margin-top: 10px; }

		a {  }
	}

	.__contact
	{
		margin-top: 15px;
		margin-bottom: 15px;

		.__title
		{
			line-height: 1;
			font-size: rem-calc(15px);
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 0.05em;
			color: $dark-color;
			margin-bottom: 15px;
		}

		.b-table
		{
			.cell
			{
				+ .cell { width: 100%; }
			}
		}

		.__ico
		{
			display: inline-block;
			line-height: 1;
			font-size: rem-calc(16px);
			margin-right: 10px;

			&:before { vertical-align: top; }
		}
	}

	.social-btns
	{
		a
		{
			font-size: rem-calc(20px);

			&.active,
			&:hover
			{
				color: $primary-color;
			}
		}
	}

	.__copy { font-size: rem-calc(13px); }

	.__dev
	{
		color: #4a8b71;

		&:hover,
		&:focus
		{
			text-decoration: underline;
		}
	}
}

.footer--style-1
{
	padding-top: $gutter * 2;
	padding-bottom: $gutter - 10px;
	font-size: rem-calc(13px);

	address
	{
		a { color: inherit; }
	}

	.social-btns
	{
		a { color: #ccc; }
	}

	#footer__navigation
	{
		ul { margin-left: -35px; }

		li
		{
			margin-left: 35px;

			&.active,
			&:hover
			{
				a
				{
					color: #c2c2c2;
				}
			}
		}

		a { color: inherit; }
	}

	.__copy { color: #b4b4b4; }
}

.footer--style-2
{
	padding-top: 75px;
	background-color: #2a2a2a;

	.footer__item
	{
		&__title { color: $white-color; }
	}

	address
	{
		color: #c4c4c4;

		a { color: $primary-color; }
	}

	.social-btns
	{
		margin-top: 15px;

		a { color: #535353; }
	}

	form
	{
		&:not(.form--horizontal)
		{
			button[type=submit]
			{
				margin-top: 20px;
			}
		}
	}

	#footer__navigation
	{
		li
		{
			display: block;
			font-size: rem-calc(14px);
			text-transform: none;

			&.active,
			&:hover
			{
				a
				{
					color: $white-color;
				}
			}
		}

		a { color: #aaa; }
	}

	.copyright
	{
		padding-top: 30px;
		border-top: 1px solid #464646;
	}

	.__copy
	{
		color: #7e7e7e;

		a { color: inherit; }
	}
}

.footer--style-3
{
	padding-top: 75px;
	background-color: #2a2a2a;

	#footer__navigation
	{
		li
		{
			display: block;
			font-size: rem-calc(14px);
			text-transform: none;

			&.active,
			&:hover
			{
				a
				{
					color: $white-color;
				}
			}
		}

		a { color: #aaa; }
	}

	address
	{
		color: $white-color;

		.__title
		{
			font-size: rem-calc(12px);
			color: $primary-color;
		}

		a { color: inherit; }
	}

	.social-btns
	{
		a { color: #535353; }
	}

	.copyright
	{
		padding-top: 30px;
		border-top: 1px solid #464646;
	}

	.__copy
	{
		color: #7e7e7e;

		a { color: inherit; }
	}

	.g_map { height: 100%; }
}

.footer--style-4
{
	padding-top: 75px;
	background-color: #333;

	@include media-breakpoint-up(sm)
	{
		padding-top: 110px;
	}

	.footer__item
	{
		&__title { color: $white-color; }
	}

	address
	{
		color: #c4c4c4;

		a { color: $primary-color; }
	}

	.social-btns
	{
		margin-top: 15px;

		a { color: #535353; }
	}

	#footer__navigation
	{
		margin-top: 0;

		ul
		{
			@include media-breakpoint-down(xs)
			{
				margin-bottom: 30px;
			}
		}

		li
		{
			display: block;
			font-size: rem-calc(14px);
			text-transform: none;

			&.active,
			&:hover
			{
				a
				{
					color: $white-color;
				}
			}
		}

		a { color: #aaa; }
	}

	.form--horizontal
	{
		@include media-breakpoint-down(xs)
		{
			.input-wrp
			{
				padding-right: 0;
				margin-bottom: 20px
			}
		}
	}

	.copyright
	{
		padding-top: 40px;
		border-top: 1px solid #464646;
	}

	.__copy
	{
		color: #7e7e7e;

		a { color: inherit; }
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	.footer--style-1
	{
		.site-logo { margin-top: -$gutter; }
	}

	.footer--style-3
	{
		.map-container
		{
			position: absolute;
			top: 0;
			right: 0;
			width: 42%;
			height: 100%;
		}
	}
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}