/* --------------------------------
   product price
-------------------------------- */

.product-price
{
	line-height: 0;
	font-size: 0;
	letter-spacing: -1px;

	&__item
	{
		display: inline-block;
		line-height: 1;
		font-size: 2rem;
		font-weight: 800;
		letter-spacing: 0;
		color: #333;

		&--new { }

		&--old
		{
			font-size: 1.4rem;
			text-decoration: line-through;
			color: #ea0d0d;
		}
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}