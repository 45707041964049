/* --------------------------------
   goods
-------------------------------- */

.goods
{
	.__item
	{
		position: relative;
		width: 100%;
	}

	.__image
	{
		position: relative;
		width: 100%;
		margin: auto;
		overflow: hidden;

		&:before
		{
			content: "";
			display: block;
		}

		img
		{
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			max-width: 100%;
			max-height: 100%;
			margin: auto;
		}
	}

	.__content
	{
		
	}

	.__title
	{
		margin-bottom: 5px;
		font-weight: 700;
		text-transform: none;
	}

	.__category
	{
		line-height: 1.4;
		font-size: 1.4rem;
		color: #666;

		a
		{
			display: inline-block;
			color: inherit;
			text-decoration: none;
		}
	}

	.rating
	{
		&__item { font-size: 1rem; }
	}

	.product-price
	{
		margin-left: -10px;
		margin-bottom: 20px;

		&__item { margin-left: 10px; }
	}

	.custom-btn
	{
		padding-left: 0;
		padding-right: 0;

		i
		{
			display: inline-block;
			vertical-align: top;
			margin-top: -7px;
			margin-bottom: -6px;
			margin-left: -5px;
			margin-right: 10px;
			line-height: 1;
			font-size: 2.2rem;
		}
	}
}

.goods--style-1
{
	$itemMarginBottom: 30px;

	.__inner { margin-bottom: -$itemMarginBottom; }

	.__item
	{
		margin-bottom: $itemMarginBottom;
		border: 1px solid #e5e5e5;
		@include transition(box-shadow 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94));

		&:hover { box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.15); }
	}

	.__image
	{
		max-height: 300px;

		&:before { padding-top: percentage(240/270); }
	}

	.__content
	{
		padding: 5px 15px 25px;

		@include media-breakpoint-up(sm)
		{
			padding-left: 30px;
			padding-right: 30px;
			padding-bottom: 35px;
		}
	}

	.__title {  }

	.product-price { margin-top: 20px; }
}

.goods--style-2
{
	$itemMarginBottom: 40px;

	.__inner { margin-bottom: -$itemMarginBottom; }

	.__item { margin-bottom: $itemMarginBottom; }

	.__image
	{
		height: 100%;
		max-height: 150px;

		&:before { padding-top: percentage(130/170); }

		img { transform: translateY(5%); }
	}


	.rating
	{
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.product-price { margin-top: 10px; }

	.product-label
	{
		top: 0;
		left: 0;
	}
}

.goods--style-3
{
	$itemMarginBottom: 30px;

	.__inner { margin-bottom: -$itemMarginBottom; }

	.__item
	{
		margin-bottom: $itemMarginBottom;
		text-align: center;

		&:hover
		{
			.__image
			{
				img { transform: scale(1.2) translateZ(0); }
			}
		}
	}

	.__image
	{
		max-height: 270px;
		border-radius: 10px;

		&:before { padding-top: percentage(195/225); }

		img
		{
			width: 100%;
			height: 100%;
			transform: translateZ(0);
			@include object-fit(cover, 50% 50%);
			@include transition(
				transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
				opacity 200ms
			);
		}
	}

	.__content
	{
		padding-top: 25px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.rating
	{
		margin-top: 7px;
		margin-bottom: 13px;
	}

	.product-price { margin-top: 10px; }

	.product-label
	{
		top: 0;
		left: 0;
	}
}

.goods--slider
{
	$itemMarginX: 30px;
	$slideGutter: 30px;

	.slick-slider
	{
		margin-top: -$itemMarginX/2;
		margin-bottom: -$itemMarginX/2;
	}

	@if $slideGutter > 0
	{
		.slick-list
		{
			margin-left: -$slideGutter/2;
			margin-right: -$slideGutter/2;
		}

		.slick-slide
		{
			padding-left: $slideGutter/2;
			padding-right: $slideGutter/2;
		}
	}

	.slick-dots
	{
		position: relative;
		margin-top: 35px;
		text-align: center;
	}

	.__item
	{
		margin-top: $itemMarginX/2;
		margin-bottom: $itemMarginX/2;
	}
}

@include media-breakpoint-up(sm)
{
	
}

@include media-breakpoint-up(md)
{
	
}

@include media-breakpoint-up(lg)
{
	
}

@include media-breakpoint-up(xl)
{
	
}