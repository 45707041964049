/* --------------------------------
   pagination
-------------------------------- */

.pagination
{
	flex-wrap: wrap;
	align-items: center;
	margin: -5px;
	border-radius: 0;

	.page-item
	{
		margin: 5px;
		@include userSelect(none);

		&:not(.active):hover
		{
			.page-link
			{
				background-color: inherit;
				// border-color: $primary-color;
				color: #4f4a37;
			}
		}

		&.active
		{
			.page-link
			{
				background-color: transparent;
				border-color: $primary-color;
				color: #4f4a37;
				cursor: default;
			}
		}

		> span
		{
			display: inline-block;
			vertical-align: top;
			line-height: 1;
		}
	}

	.page-link
	{
		min-width: 36px;
		height: 36px;
		margin-left: 0;
		padding: 0;
		line-height: 31px;
		font-size: 1.2rem;
		font-weight: 700;
		font-family: $secondary-fontFamily;
		color: #a9a9a9;
		text-align: center;
		text-decoration: none;
		border: 2px solid #e9e9e9;
		border-radius: 50% !important;
		box-shadow: none;
		@include transition(
			background-color 0.3s ease-in-out,
			border-color     0.3s ease-in-out,
			color            0.3s ease-in-out
		);
	}
}

@include min-screen($sm-width)
{

}

@include min-screen($md-width)
{

}

@include min-screen($lg-width)
{

}

@include min-screen($xl-width)
{

}